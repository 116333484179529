<template>
  <div style="padding: 20px">
    <el-table :data="list" border stripe>
      <el-table-column prop="nickname" label="小店艺术家候选名单"></el-table-column>
      <el-table-column prop="valid_vote_num" label="真实票数"> </el-table-column>
      <el-table-column prop="fake_vote_num" label="已新增票数"> </el-table-column>
      <el-table-column prop="total_vote_num" label="当前显示票数"> </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="handleAdd(scope.row)">
            新增票数
          </el-button>
          <el-button size="mini" type="danger" @click="handleSubtract(scope.row)">
            删减票数
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="新增票数"
      :visible.sync="addVisible"
      width="420px"
      @close="addVisible = false"
    >
      <div>
        <el-input
          v-model="addValue"
          placeholder="请输入新增票数"
          size="normal"
          clearable
          @input="clearAddError"
        />
      </div>
      <div style="height: 20px; color: red; opacity: 0.55; font-size: 12px; padding: 4px 0">
        {{ addError }}
      </div>
      <span slot="footer">
        <el-button @click="addVisible = false">取消</el-button>
        <el-button type="primary" @click="handleConfirmAdd">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="删减票数"
      :visible.sync="subtractVisible"
      width="420px"
      @close="subtractVisible = false"
    >
      <div>
        <el-input
          v-model="subtractValue"
          placeholder="请输入删减票数"
          size="normal"
          clearable
          @input="clearSubtractError"
        />
      </div>
      <div style="height: 20px; color: red; opacity: 0.55; font-size: 12px; padding: 4px 0">
        {{ subtractError }}
      </div>
      <div>最多删减 {{ currItem && currItem.fake_vote_num }} 票</div>
      <span slot="footer">
        <el-button @click="subtractVisible = false">取消</el-button>
        <el-button type="primary" @click="handleConfirmSubtrac">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCampaignArtistsList, addVoteCount, subtractVoteCount } from "@/api/nft.js";

export default {
  data() {
    return {
      list: [
        {
          ucid: "123",
          avatar: "",
          nickname: "阿大声道",
          total_vote_num: 10,
          valid_vote_num: 6,
          fake_vote_num: 4,
        },
      ],

      addVisible: false,
      subtractVisible: false,

      addValue: "",
      subtractValue: "",

      addError: "",
      subtractError: "",

      currItem: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await getCampaignArtistsList();
        console.log(data);
        this.list = data.data.items || [];
      } catch (error) {
        console.log(error);
      }
    },
    checkIsPositiveInt(val) {
      const isPositiveInt = /^[1-9]\d*$/;
      return isPositiveInt.test(val);
    },

    handleAdd(item) {
      this.currItem = item;
      this.addVisible = true;
    },
    clearAddError() {
      this.addError = "";
    },
    handleConfirmAdd() {
      if (!this.checkIsPositiveInt(this.addValue)) {
        this.addError = "请输入正整数";
        return;
      }

      if (+this.addValue > 100) {
        this.addError = "不能大于100";
        return;
      }

      this.add();
    },
    async add() {
      const params = {
        target_ucid: this.currItem.ucid,
        vote_num: +this.addValue,
      };
      try {
        await addVoteCount(params);
        this.$message.success("新增成功");
        this.fetchData();
        this.addVisible = false;
        this.addValue = "";
      } catch (error) {
        console.log(error);
      }
    },

    handleSubtract(item) {
      this.currItem = item;
      this.subtractVisible = true;
    },
    clearSubtractError() {
      this.subtractError = "";
    },
    handleConfirmSubtrac() {
      if (!this.checkIsPositiveInt(this.subtractValue)) {
        this.subtractError = "请输入正整数";
        return;
      }

      if (+this.subtractValue > 100) {
        this.subtractError = "不能大于100";
        return;
      }

      const max = +this.currItem.fake_vote_num;
      if (+this.subtractValue > max) {
        this.subtractError = "不能超过" + max;
        return;
      }

      this.subtract();
    },

    async subtract() {
      const params = {
        target_ucid: this.currItem.ucid,
        vote_num: +this.subtractValue,
      };
      try {
        await subtractVoteCount(params);
        this.$message.success("删减成功");
        this.fetchData();
        this.subtractVisible = false;
        this.subtractValue = "";
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
